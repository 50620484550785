
.apartments-list-container {
    height: calc(100% - 90px);
    margin-top: 90px;
    width: 95%;
    background-color: rgba(0, 0, 0, 0.30);
    overflow-y: auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;

    row-gap: 50px;
    padding: 10px;
}

.header-apartment-container {
    grid-area: 1 / 1 / 2 / 3;
    margin: 0;
}

@media only screen and (max-width: 800px) {

    .header-apartment-container {
        grid-area: unset;
    }

    .apartments-list-container {
        grid-template-columns: 1fr;
    }
}