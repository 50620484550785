
@font-face {
	font-family: Roboto-Black;
	src: url("../fonts/Roboto-Black.ttf") format("truetype");
}

@font-face {
	font-family: Roboto-Bold;
	src: url("../fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
	font-family: Roboto-Medium;
	src: url("../fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
	font-family: Roboto-Thin;
	src: url("../fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
	font-family: Roboto-Light;
	src: url("../fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
	font-family: Roboto;
	src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}

* {
	font-family: Roboto-Light;
}

@font-face {
    font-family: 'Rotunda-Thin';
    src: url('../fonts/Rotunda-Thin.otf') format('opentype');
}

@font-face {
    font-family: 'Rotunda-Light';
    src: url('../fonts/Rotunda-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Rotunda';
    src: url('../fonts/Rotunda-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Rotunda-Medium';
    src: url('../fonts/Rotunda-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Rotunda-Bold';
    src: url('../fonts/Rotunda-Extrabold.otf') format('opentype');
}

@font-face {
    font-family: 'Rotunda-Black';
    src: url('../fonts/Rotunda-Black.otf') format('opentype');
}