.footer-container {
    background-color: #27272a;
    color: white;
    font-family: Roboto-Light;
    user-select: none;
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-top: 50px;
    
    .footer-container-title {
        display: flex;
        align-items: center;
        gap: 20px;

        .footer-title {
            font-size: 1.5rem;
            font-family: Roboto;

            &::after {
                content: "";
                display: block;
                border-bottom: 1px solid white;
            }
        }
    }

    .footer-subtitle {
        font-size: 1.3rem;
        font-family: Roboto-Light;
    }

    strong {
        font-family: Roboto-Bold, sans-serif;
        letter-spacing: 1px;
    }

    .sub-sections {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 50px;
    }

    .footer-options-container {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: rgb(173, 173, 173);
        font-family: Roboto-SemiBold;
        padding: 0;
        cursor: pointer;

        & > li:hover {
            font-family: Roboto-Bold;
        }
    }

    .company-developed {
        display: flex;
        justify-content: center;
        gap: 10px;
        padding-bottom: 5px;
        font-size: 0.75rem;
    }
}