@import './home.scss';
@import './header.scss';
@import './apartments.scss';
@import './apartment.scss';
@import './fonts.scss';
@import './footer.scss';

html, body, #root  {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0;
    background-color: white;
    color: black;
}

.container {
    position: relative;
    height: 100%;
    width: 100%;
}

.container-pages {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0
}

.content {
    display: flex;
    
    height: 100%;
    width: 100%;

    overflow-x: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;

    & > div {
        flex: 0 0 100%;
        height: 100%;
    }
}

.action-container {
    position: absolute;
    bottom: 30px;
    right: 40px;

    & > .carrousel-action {
        font-size: 2rem;
        color: lightgrey;
    }
}

.actions-carrousel {
    position: absolute;
    left: 40px;
    bottom: 30px;

    & > .carrousel-action {
        font-size: 2rem;
        color: lightgrey;
    }
}

.title-shadow-background {
	position: absolute;
    background-color: rgba(233, 165, 113, 0.21);
    width: 50%;
    z-index: 0;
    top: 20px;
    border-radius: 50%;
    left: 62px;
    filter: blur(120px);
}

li {
    white-space: pre-line;
    word-break: break-word;
    font-family: Roboto-Thin;
    font-size: 0.75rem;
    line-height: 2;
}

.ant-btn-variant-solid  {
    background-color: #505050;
    font-family: Roboto-Thin;
    font-size: 0.8rem;
    height: 35px;
}

.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #1d1d1d;
}

.progress {
    background: rgba(255, 255, 255, 0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 40px;
    width: 500px;
}
  
.progress-value {
    animation: load 1.5s normal forwards;
    box-shadow: 0 10px 40px -10px #4c4c4c;
    border-radius: 100px;
    background: #4c4c4c;
    height: 10px;
    width: 0;
}

.ant-image .ant-image-img {
    height: 100%;
    object-fit: cover;
}
  
@keyframes load {
    0% { width: 0; }
    100% { width: 100%; }
}

/** Change style of the notification title */
.ant-notification-notice-message {
    font-size: 14px;
    font-family: Rotunda-Black;
}

/** Change the style of the notification description */
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 11px;
    font-family: Roboto-Thin;
}

/** Custom successfull notifcation message */
.ant-notification-notice-success {
    padding: 10px 15px!important;

    .ant-notification-notice-message {
        font-family: Roboto-Thin;
        font-size: 14px!important;
    }

    .ant-notification-notice-icon {
        font-size: 20px!important;
    }

    .ant-notification-notice-close {
        top: 13px!important;
    }
}

.ant-layout-header {
    background-color: #e6e6e6;
    color: black;
}

.ant-layout-content {
    overflow-y: auto;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
}

.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    box-shadow: none;
    border: none;
}

.ant-input-outlined:hover {
    border-color: none!important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    height: 100%;
}