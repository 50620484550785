
.header-container {
    width: 40%;

    .navbar {
        height: 100px;
    }
}

.hamburger {
    display: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #7c7c7c;
}

.ant-drawer-right>.ant-drawer-content-wrapper {
   width: 250px;
}

.ant-drawer .ant-drawer-content {
    background-color: white;
}

.drawer-item {
    font-family: Roboto-Thin;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
        font-family: Roboto;
    }

    &.selected {
        font-family: Roboto-Bold;
        letter-spacing: 1px;
    }
}

.ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

@media only screen and (max-width: 1050px) {
    .header-container {
        justify-content: space-between;
    }
    
    .navbar {
        display: none!important;
    }

    .hamburger {
        display: flex!important;
    }
}
